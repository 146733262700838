<template>
  <div class="series">
    <div class="container row">
      <!-- Image -->
      <div class="col-12 col-lg-4" v-if="new_version === false">
        <div class="img-thumbnail">
          <img
            class="img-fluid"
            v-lazy="makeImage(data.image, 800, 600, true)"
            :alt="data.name"
          />
        </div>
      </div>
      <!-- Table -->
      <div
        :class="new_version === false ? 'col-12 col-lg-8' : 'col-12 col-lg-12'"
      >
        <div v-if="new_version === false">
          <h1>{{ data.name }}</h1>
          <p>
            <strong>Event Date: </strong>
            <span>{{ data.date }} at {{ data.time }} GMT +4</span>
          </p>
          <p>
            <strong>Registration closing at: </strong>
            <span>{{ data.date_close }} {{ data.time_close }} GMT +4</span>
          </p>
        </div>

        <!-- State Table -->
        <div style="overflow-x: auto">
          <table class="table" v-if="getCatData('Category 1')">
            <thead>
              <tr>
                <th>Category Type</th>
                <th v-if="getCatData('Category 1')">Category - 1</th>
                <th v-if="getCatData('Category 2')">Category - 2</th>
                <th v-if="getCatData('Category 3')">Category - 3</th>
                <th v-if="getCatData('Category 4')">Category - 4</th>
                <th v-if="getCatData('Category 5')">Category - 5</th>
                <th v-if="getCatData('Category 6')">Category - 6</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Time (GMT +4)</td>
                <td v-if="getCatData('Category 1')">
                  {{ getCatData("Category 1").time }}
                </td>
                <td v-if="getCatData('Category 2')">
                  {{ getCatData("Category 2").time }}
                </td>
                <td v-if="getCatData('Category 3')">
                  {{ getCatData("Category 3").time }}
                </td>
                <td v-if="getCatData('Category 4')">
                  {{ getCatData("Category 4").time }}
                </td>
                <td v-if="getCatData('Category 5')">
                  {{ getCatData("Category 5").time }}
                </td>
                <td v-if="getCatData('Category 6')">
                  {{ getCatData("Category 6").time }}
                </td>
              </tr>
              <tr>
                <td>Distance</td>
                <td v-if="getCatData('Category 1')">
                  {{ getCatData("Category 1").distance }} KM
                </td>
                <td v-if="getCatData('Category 2')">
                  {{ getCatData("Category 2").distance }} KM
                </td>
                <td v-if="getCatData('Category 3')">
                  {{ getCatData("Category 3").distance }} KM
                </td>
                <td v-if="getCatData('Category 4')">
                  {{ getCatData("Category 4").distance }} KM
                </td>
                <td v-if="getCatData('Category 5')">
                  {{ getCatData("Category 5").distance }} KM
                </td>
                <td v-if="getCatData('Category 6')">
                  {{ getCatData("Category 6").distance }} KM
                </td>
              </tr>
              <tr>
                <td>Elevation</td>
                <td v-if="getCatData('Category 1')">
                  {{ getCatData("Category 1").elevation }} Meters
                </td>
                <td v-if="getCatData('Category 2')">
                  {{ getCatData("Category 2").elevation }} Meters
                </td>
                <td v-if="getCatData('Category 3')">
                  {{ getCatData("Category 3").elevation }} Meters
                </td>
                <td v-if="getCatData('Category 4')">
                  {{ getCatData("Category 4").elevation }} Meters
                </td>
                <td v-if="getCatData('Category 5')">
                  {{ getCatData("Category 5").elevation }} Meters
                </td>
                <td v-if="getCatData('Category 6')">
                  {{ getCatData("Category 6").elevation }} Meters
                </td>
              </tr>
              <tr v-if="data.route_type != 0 && data.laps > 0">
                <td>No. of laps</td>
                <td v-if="getCatData('Category 1')">{{ data.laps }}</td>
                <td v-if="getCatData('Category 2')">{{ data.laps }}</td>
                <td v-if="getCatData('Category 3')">{{ data.laps }}</td>
                <td v-if="getCatData('Category 4')">{{ data.laps }}</td>
                <td v-if="getCatData('Category 5')">{{ data.laps }}</td>
                <td v-if="getCatData('Category 6')">{{ data.laps }}</td>
              </tr>
              <tr>
                <td colspan="7" class="bottom"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="d-flex justify-content-between"
          v-if="new_version === false"
        >
          <div>
            <strong>Participants:</strong>
            <span>
              {{ data.participants.booked }} /
              {{ data.participants.capacity }}
            </span>
          </div>

          <template>
            <a
              class="btn btn-link"
              @click="doToggle"
              href="javascript:void(0)"
              v-if="!toggle"
            >
              More Details &raquo;
            </a>
            <a
              class="btn btn-link"
              @click="doToggle"
              href="javascript:void(0)"
              v-if="toggle"
            >
              Less Details &laquo;
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { commonMixin } from "@/mixins/commonMixin";

export default {
  name: "EventCategoryTimes.vue",
  mixins: [commonMixin],
  props: ["data", "details", "new_version"],

  data() {
    return {
      toggle: false,
    };
  },

  methods: {
    getCatData(name) {
      const getEvent = this.data?.event ?? this.data;
      if (!getEvent?.categories[name]) return;
      return getEvent?.categories[name][0];
    },

    doToggle() {
      this.toggle = !this.toggle;
      this.$emit("doToggle", this.toggle); // You can also send up a value(object)
    },
  },
};
</script>

<style scoped>
.series {
  font-family: "Montserrat", sans-serif;
  /* padding-top: 20px;
    padding-bottom: 20px; */
  color: #59636b;
}

.series h1 {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #1d1e1f;
  /* width: 61%; */
  padding: 0;
  margin: 0;
}

.series .img-thumbnail {
  background: none;
  border: 0;
  border-radius: 0;
  overflow: hidden;
}

.series .table {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-collapse: initial;
  border-spacing: 0 5px;
  /* border-radius: 8px; */
  border: 0;
  margin: 0 0 5px;
}

.series p {
  margin-bottom: 0px;
}

.series .table tr {
  margin-bottom: 5px;
}

.series .table th,
.series .table td {
  border: 0;
  padding: 10px 15px;
}

.series .table th {
  font-weight: 600;
  font-size: 14px;
}

.series .table .bottom {
  background-color: #000000 !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 10px !important;
  padding: 0;
}
.series .table th:not(:first-child) {
  background: #fcb915;
  padding-left: 25px;
}

.series .table td:not(:first-child) {
  background: #ffffff;
}

.series .table th:first-child {
  background: #e3b134;
  /* border-top-left-radius: 8px; */
}

.series .table th:last-child {
  /* border-top-right-radius: 8px; */
}

.series .table td:first-child {
  background: #eeebe3;
  font-weight: 600;
}

.series .table td:not(:first-child) {
  padding-left: 25px;
}

.series .btn {
  width: auto;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #59636b;
  display: block;
  text-decoration: underline;
}

.series .btn + .btn {
  margin-left: 15px;
}

.series .btn-link {
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .series h1 {
    font-size: 16px !important;
  }
}
strong {
  font-weight: bold !important;
}
</style>
